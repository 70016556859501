import React, { FC } from 'react';

import TextBlock from 'components/common/TextBlock';
import QuoteBlock from 'components/common/QuoteBlock';
import FeaturedImage from 'components/common/FeaturedImage';
import BodyRenderer from 'components/common/BodyRenderer';
import VideoPlayer from 'components/VideoBlock/VideoPlayer';

import { IArticleBodyProps } from './model';
import './ArticleBody.scss';
import ItemsGrid from '../common/ItemsGrid';

const elements = {
  'Text Block': ({ properties: { text } }, keyId) => <TextBlock key={keyId} text={text} />,
  'Items Grid': ({ properties: { text, grid, link } }, keyId) => (
    <ItemsGrid text={text} link={link} grid={grid} key={keyId} />
  ),
  'Quote Block': ({ properties: { text } }, keyId) => <QuoteBlock key={keyId} text={text} />,
  'Image Block': ({ properties: { image, imageAlt } }, keyId) => (
    <FeaturedImage alt={imageAlt} key={keyId} img={image} />
  ),
  'Video Block': (
    {
      properties: {
        link,
        preTitle,
        secText,
        minText,
        videoPreviewPoster,
        videoPreviewPosterAlt,
        playButtonAria,
      },
    },
    keyId
  ) => (
    <VideoPlayer
      key={keyId}
      onlyVideo
      link={link}
      preTitle={preTitle}
      timeNames={{ sec: secText, min: minText }}
      videoPreviewPoster={videoPreviewPoster}
      videoPreviewPosterAlt={videoPreviewPosterAlt}
      playButtonAria={playButtonAria}
    />
  ),
};

const ArticleBody: FC<IArticleBodyProps> = ({
  content,
  date = '',
  dateText = '',
  videoSettings,
  hideTeaserDate,
}) => {
  const articleDate = `${dateText} ${date}`.trim();

  return (
    <div className="article-body">
      <BodyRenderer bodyData={content} bodyItemProps={videoSettings} bodyStructure={elements} />
      {!hideTeaserDate && date ? <p className="article-body__date">{`${articleDate}`}</p> : null}
    </div>
  );
};

export default ArticleBody;
